<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸智能算法全面上线中金公司
              </p>
              <div class="fst-italic mb-2">2024年9月20日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >中金公司</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/89/01-中金上线.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，非凸智能拆单算法成功上线中金公司，全新升级，平稳运行，全方位满足投资者降低交易成本、优化交易执行、提升交易效率等切实需求。基于行业领先的技术创新，非凸科技将通过高性能交易、高效率风控、高质量服务，与中金公司携手拓展数智交易共赢新格局。
                  </p>
                  <p>
                    业务创新与不断涌现的市场需求，正推动行业进入一个充满机遇与变革的新时代。自成立以来，非凸科技始终站在技术创新的前沿，积极拥抱以AI智能算法为代表的一站式数智交易领域服务解决方案，助力机构业务快速发展。非凸智能算法利用多因子数学模型自动化、智能化地进行拆单，通过大单拆小单，有效降低市场冲击，从而降低交易成本。对机构而言，可以大幅提高交易效率，降低交易部门的人力成本。此次合作，非凸科技将不断追求卓越，力求将每一次升级都做到极致，为客户提供更加高效、稳定、智能的交易体验。
                  </p>
                  <p>
                    只有真正站在客户的角度，才能打造出真正符合市场需求的交易工具。未来，非凸科技将与中金公司继续携手并进，共同打造精品项目，推动业务与科技的深度融合，赋能业务创新发展。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News89",
};
</script>

<style></style>
